import React from 'react'
import LoginPage from "components/container/LoginPage/LoginPage";
import { TwoFAForm } from 'components/forms/TwoFAForm/TwoFAForm'

const TwoFA = () => {
	return (
		<LoginPage Form={TwoFAForm} />
	)
}
export default TwoFA;
