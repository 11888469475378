import React, { useRef, useState } from 'react'
import twoFaLogin from '../../../../static/media/two-fa-login.png'
import {
	ENTER_CODE_ERROR_MESSAGE,
	ENTER_CODE_LABEL,
	ENTER_CODE_MESSAGE,
	LINK_HAS_EXPIRED,
} from 'utils/messages'
import Loader from 'components/global/Loader/Loader'
import { ButtonTypes, ButtonSizes, ButtonLabels } from 'utils/constants'
import { Button } from 'components/buttons/Button/Button'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { navigate } from 'gatsby'
import { Icons, IconsNames } from 'utils/icons'
import { Hint } from 'components/global/Hint/Hint'
import { useForm } from 'react-hook-form'

const TwoFaFormContainer = styled.form`
	${tw`w-580 h-580 flex flex-col items-center justify-center shadow-loginForm rounded-3xl`}
`
const TwoFaLoaderContainer = styled.div`
	${tw`w-580 h-580 flex flex-col items-center justify-center`}
`
const TwoFaHeader = styled.div`
	${tw`font-base pt-6 font-semibold text-mediumLarge leading-large text-dark-blue mb-2`}
`
const TwoFaText = styled.div`
	${tw`text-base pb-1`}
`
const TwoFaButtonsContainer = styled.div`
	${tw`mt-6 flex flex-row gap-3`}
`
const ImgContainer = styled.img`
	${tw`h-86 w-97`}
`
const InputsContainer = styled.div`
	${tw`flex flex-row gap-5 pt-2`}
`
const TwoFaInput = styled.input`
	${tw`w-9 h-9 rounded`}
`

export const TwoFAForm = () => {
	const [isLoading, setIsLoading] = useState()
	const { handleSubmit } = useForm()
	const [showError, setShowError] = useState(false)
	const [code, setCode] = useState([])
	const ref = useRef([])

	const inputIds = ['code-0', 'code-1', 'code-2', 'code-3', 'code-4', 'code-5']

	const handleNextInput = (e) => {
		const fieldIndex = +e.target.id.split('-')[1]
		if (code[fieldIndex]) {
			const copy = [...code]
			copy[fieldIndex] = e.target.value
			setCode(copy)
		} else {
			setCode((prev) => [...prev, e.target.value])
		}
		ref?.current[fieldIndex + 1]?.focus()
	}

	const onSubmit = async () => {
		setIsLoading(true)
		try {
			// const response = await fetchApi(LOGIN_CHECK_MFA(userID), {
			// 	method: FetchMethods.Post,
			// 	data: {
			// 		code: +code?.join(''),
			// 	},
			// })

			await navigate('/app/dashboard/')
		} catch (error) {
			setShowError(true)
		} finally {
			setIsLoading(false)
		}
	}

	const handleBack = async () => {
		await navigate('/login')
	}

	if (isLoading) {
		return (
			<TwoFaLoaderContainer>
				<Loader />
			</TwoFaLoaderContainer>
		)
	}

	const errorHintConfig = {
		message: ENTER_CODE_ERROR_MESSAGE,
		type: 'error',
		icon: Icons[IconsNames.ExclamationMark],
	}

	return (
		<TwoFaFormContainer>
			<ImgContainer alt={LINK_HAS_EXPIRED} src={twoFaLogin} />
			<TwoFaHeader>{ENTER_CODE_LABEL}</TwoFaHeader>
			<TwoFaText>{ENTER_CODE_MESSAGE}</TwoFaText>
			{showError && <Hint config={errorHintConfig} />}
			<InputsContainer>
				{inputIds.map((id, index) => {
					return (
						<TwoFaInput
							key={id}
							className="two-fa-input"
							id={id}
							type="text"
							placeholder="_"
							maxLength="1"
							ref={(inputId) => (ref.current[index] = inputId)}
							onChange={(e) => handleNextInput(e)}
						/>
					)
				})}
			</InputsContainer>
			<TwoFaButtonsContainer>
				<Button
					handleOnClick={handleSubmit(handleBack)}
					label={ButtonLabels.Back}
					type={ButtonTypes.Secondary}
					size={ButtonSizes.Medium}
					dataTestId="button-step-back"
				/>
				<Button
					handleOnClick={handleSubmit(onSubmit)}
					label={ButtonLabels.Next}
					type={ButtonTypes.Primary}
					size={ButtonSizes.Medium}
					dataTestId="button-step-next"
				/>
			</TwoFaButtonsContainer>
		</TwoFaFormContainer>
	)
}
